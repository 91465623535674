import {
  BusinessInfoDoc,
  CallFindEmailRequest,
  CallFindEmailResponse,
  CommerceConf,
  CustomPromotionSectionDoc,
  DeleteAccountRequest,
  InvoiceDetailDoc,
  InvoiceDoc,
  OrderDoc,
  OrderStatusCode,
  ProductDoc,
  ProductStateCode,
  PromotionSectionDoc,
  Store,
  StoreDoc,
  UserDoc,
} from '@gooduncles/gu-app-schema';
import { arrayRemove, arrayUnion } from 'firebase/firestore';
import { chunk, groupBy, range } from 'lodash-es';
import { map } from 'rxjs';
import { GaEvent } from 'src/schema/schema-ga-event';

import { formatDate } from '../1/date-util';
import { FirebaseManager, QueryOption, WHERE } from '../2/firebase-manager';
import { CallCreateUserRequest, CallCreateUserResponse } from '../2/schema-on-call';
import { StoreIssueDoc } from '../2/schema-store-issue';
import { InstallmentBillDoc } from './schema-bill';
import { NotificationDoc } from './schema-notification';
import { ProductGroupForTagDoc } from './schema-product-group-for-category';
import { ProductGroupForCategorySubsetDoc } from './schema-product-group-for-category-subset';

export interface QueryOptions {
  sortKey: string;
  orderBy: 'asc' | 'desc';
  startValue?: any;
  endValue?: any;
  limit?: number;
}

/**
 * redux & ga에서 사용불가한
 * Timestamp를 제거한다.
 */
export const deleteTimestamp = (data: any) => {
  delete data._timeUpdate;
  delete data._timeCreate;
  delete data._timeDelete;
  return data;
};

const fb = FirebaseManager.getInstance();

/** 사용자 생성 */
export const callCreateUser = fb.getCallable<CallCreateUserRequest, CallCreateUserResponse>('callCreateUser');
/** 이메일 찾기 */
export const callFindEmail = fb.getCallable<CallFindEmailRequest, CallFindEmailResponse>('callFindEmail');

const userPath = 'user';
const storePath = 'store';
const productPath = 'product';
const commerceConfPath = 'config/commerce';
const businessInfoConfPath = 'config/businessInfo';
const notificationPath = 'notification';
const installmentBillPath = 'installmentBill';
const storeIssuePath = 'storeIssue';
const productGroupForCategorySubsetPath = 'productGroupForCategorySubset';

// Auth
export const observeFriebaseAuth = () => fb.observeAuthState();
export const login = (email: string, password: string) => fb.signIn(email, password);
export const logout = () => fb.signOut();

// GA Event
export const sendGaEvent = (event: GaEvent) => fb.gaLogEvent(event.name, event.eventParams);
export const setUserForGa = (uid: string) => fb.setGaUserId(uid);
export const setUserPropertiesForGa = (userProperties: any) => fb.setGaUserProperties(userProperties);

// User
export const observeUserDoc = (uid: string) => fb.observeDoc<UserDoc>(`${userPath}/${uid}`);
export const getUserDoc = async (uid: string) => fb.getDoc<UserDoc>(`${userPath}/${uid}`);
export const updateUser = (uid: string, data: Partial<UserDoc>) => fb.updateDoc(userPath, uid, data);
export const setFavoriteProductsForUser = (uid: string, favoriteProducts: string[]) =>
  fb.updateDoc(userPath, uid, { favoriteProducts });
export const addFavoriteProduct = (uid: string, productId: string) =>
  fb.updateDoc(userPath, uid, {
    favoriteProducts: arrayUnion(productId),
  });
export const removeFavoriteProduct = (uid: string, productId: string) =>
  fb.updateDoc(userPath, uid, {
    favoriteProducts: arrayRemove(productId),
  });

// Store
export const observeStoreDoc = (storeId: string) => fb.observeDoc<StoreDoc>(`${storePath}/${storeId}`);
export const getStoreDoc = async (storeId: string) => fb.getDoc<StoreDoc>(`${storePath}/${storeId}`);
export const updateStore = async (storeId: string, data: Partial<Store>) => fb.updateDoc(storePath, storeId, data);

// Config
export const getCommerceConf = (fromServer = false) => fb.getDoc<CommerceConf>(commerceConfPath, fromServer);
export const observeCommerceConf = () => fb.observeDoc<CommerceConf>(commerceConfPath);
export const getBusinessInfoConf = (fromServer = false) => fb.getDoc<BusinessInfoDoc>(businessInfoConfPath, fromServer);

// Notification
export const getNotificationDoc = (notificationId: string) =>
  fb.getDoc<NotificationDoc>(`${notificationPath}/${notificationId}`);
export const updateNotificationDoc = (notificationId: string, updateData: Partial<NotificationDoc>) =>
  fb.updateDoc(notificationPath, notificationId, updateData);

// Order
const orderPath = 'order';
export const cancelOrder = async (orderId: string) =>
  fb.updateDoc(orderPath, orderId, { orderStatus: OrderStatusCode.CANCELED });
/** 완료된 주문(수락, 배송완료)이 있는지 확인한다. */
export const getOrderedOrderCountForStore = async (storeId: string) => {
  const where: WHERE[] = [
    ['storeId', '==', storeId],
    ['orderStatus', 'in', [OrderStatusCode.DELIVERED, OrderStatusCode.ACCEPTED]],
  ];
  return fb.getCollectionCount(orderPath, where);
};
export const orderSubscriptionForStore = (storeId: string, monthRange = 6) => {
  const now = new Date();
  const endDate = formatDate(new Date(now.setMonth(now.getMonth() - monthRange)), "yyyy-MM-dd'T'HH:mm:ss+0900");
  const where: WHERE[] = [
    ['storeId', '==', storeId],
    ['orderDate', '>=', endDate],
  ];
  return fb
    .observeCollection<OrderDoc>(orderPath, where, {
      sortKey: 'orderDate',
      orderBy: 'desc',
    })
    .pipe(map((orders) => groupBy(orders, 'orderStatus')));
};

// Product
export const getProductDoc = async (productId: string) => fb.getDoc<ProductDoc>(`${productPath}/${productId}`);
export type GetProductsForKeywordsResponse = {
  products: ProductDoc[];
  hasMore: boolean;
};
export const getProductsForKeywords = async (
  keywords: string[],
  limit: number
): Promise<GetProductsForKeywordsResponse> => {
  const chunks = chunk(keywords, 10);

  const minLength = Math.min(chunks.length, limit);
  const promises = range(minLength).map(async (idx) => {
    const where: WHERE[] = [['fullName', 'in', chunks[idx]]];
    return fb.getDocsArrayWithWhere<ProductDoc>(productPath, where);
  });
  const response = await Promise.all(promises);
  const products = response
    .flat()
    .filter((product) => [ProductStateCode.STOCK, ProductStateCode.OUTOFSTOCK].includes(product.state));
  return {
    products,
    hasMore: chunks.length > limit,
  };
};
export const getProductsWithWhere = async (where: WHERE[], queries: QueryOption[], itemsPerQuery?: number) =>
  fb.getDocsArrayWithQuery<ProductDoc>(productPath, where, queries, itemsPerQuery);

/**
 * 특정 카테고리의 유효한 상품 개수를 가져온다.(재고가 없는 상품 포함)
 * @param category
 */
export const getProductCountForCategory = async (category: string) => {
  const where: WHERE[] = [
    ['categories', 'array-contains', category],
    ['state', 'in', [ProductStateCode.STOCK, ProductStateCode.OUTOFSTOCK]],
  ];
  return fb.getCollectionCount(productPath, where);
};

// InstallmentBill
export const getInstallmentBill = (docId: string) => fb.getDoc<InstallmentBillDoc>(`${installmentBillPath}/${docId}`);

export const observeInstallmentBillsForStore = (storeCode: string) =>
  fb.observeCollection<InstallmentBillDoc>(installmentBillPath, [['storeCode', '==', storeCode]]);

export const getInstallmentBillsForStore = (storeCode: string) =>
  fb.getDocsArrayWithWhere<InstallmentBillDoc>(installmentBillPath, [['storeCode', '==', storeCode]]);

// StoreIssue
export const getStoreIssuesForStoreWithWhere = (wheres: WHERE[] = [], options?: QueryOptions) =>
  fb.getDocsArrayWithWhere<StoreIssueDoc>(storeIssuePath, wheres, options);

export const getStoreIssue = (docId: string) => fb.getDoc<StoreIssueDoc>(`${storeIssuePath}/${docId}`);

// productGroupForCategorySubset
export const getProductGroupForCategorySubset = async (category: string) =>
  fb.getDoc<ProductGroupForCategorySubsetDoc>(`${productGroupForCategorySubsetPath}/${category}`);

export const observeProductGroupForCategorySubset = () =>
  fb.observeCollection<ProductGroupForCategorySubsetDoc>(productGroupForCategorySubsetPath, []);

// productGroupForTag
const productGroupForTagPath = 'productGroupForTag';
export const getProductGroupForTag = async (tag: string) =>
  fb.getDoc<ProductGroupForTagDoc>(`${productGroupForTagPath}/${tag}`);

// promotionSection
const promotionSectionPath = 'promotionSection';
export const observePromotionSection = (where: WHERE[] = [], options?: QueryOptions) =>
  fb.observeCollection<PromotionSectionDoc>(promotionSectionPath, where, options);

// invoice
const invoicePath = 'invoice';
export const getInvoice = async (invoiceId: string) => fb.getDoc<InvoiceDoc>(`${invoicePath}/${invoiceId}`);

// invoiceDetail
const invoiceDetailPath = 'invoiceDetail';
export const getInvoiceDetail = async (invoiceId: string) =>
  fb.getDoc<InvoiceDetailDoc>(`${invoiceDetailPath}/${invoiceId}`);

// deleteAccountRequest
const deleteAccountRequestPath = 'deleteAccountRequest';
export const getDeleteAccountRequest = async (id: string) =>
  fb.getDoc<DeleteAccountRequest>(`${deleteAccountRequestPath}/${id}`);
export const createDeleteAccountRequest = async (data: DeleteAccountRequest) =>
  fb.createDoc(deleteAccountRequestPath, data._id, data);

// customPromotionSection
const customPromotionSectionPath = 'customPromotionSection';
export const observeCustomPromotionSectionFor = (storeId: string) =>
  fb.observeCollection<CustomPromotionSectionDoc>(customPromotionSectionPath, [['storeId', '==', storeId]]);
